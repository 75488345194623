import React from 'react';
import { Box } from '@mui/material';

const Location = () => {
    return (
        <div>
            <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
                This feature will be available soon
            </Box>
        </div>
    );
};

export default Location;
