import React, { ChangeEventHandler } from 'react';
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import AppModal from '../Shared/AppModal';
import { AdminFieldErrorKeys } from '../../types';

interface UserFormModalProps {
    open: boolean;
    isWorking: boolean;
    handleClose: (
        event: object,
        reason: 'backdropClick' | 'escapeKeyDown' | 'none'
    ) => void;
    onRoleChange: (event: SelectChangeEvent<string>) => void;
    onEmailChange:
        | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
        | undefined;
    onSave: ((event: object) => void) | undefined;
    email: string;
    role: string;
    isEdit: boolean;
    error: string;
    fieldErrors: Record<AdminFieldErrorKeys, string>;
}

const AdminForm = (props: UserFormModalProps) => {
    const emailError = props.fieldErrors?.user_name || '';
    const roleError = props.fieldErrors?.role || '';
    const emailFieldAttr = props.isEdit
        ? {
              input: {
                  readOnly: true,
                  disabled: true,
              },
          }
        : {};
    return (
        <AppModal open={props.open} handleClose={props.handleClose}>
            <Typography variant="h5" gutterBottom>
                {props.isEdit ? 'Edit Admin' : 'Add New Admin'}
            </Typography>
            {props.error ? (
                <Alert severity="error" style={{ margin: '10px 0' }}>
                    {props.error}
                </Alert>
            ) : (
                <></>
            )}
            <Box component="form" noValidate autoComplete="off">
                <TextField
                    type={'email'}
                    slotProps={emailFieldAttr}
                    label="Email Address"
                    fullWidth
                    margin="normal"
                    onChange={props.isEdit ? () => false : props.onEmailChange}
                    value={props.email || ''}
                    error={emailError ? true : false}
                    helperText={emailError ? emailError : ''}
                />
                <FormControl fullWidth>
                    <InputLabel>Role</InputLabel>
                    <Select
                        disabled={props.isWorking}
                        label="Role"
                        onChange={props.onRoleChange}
                        value={props.role || ''}
                        error={roleError ? true : false}
                    >
                        <MenuItem value={'Admin'}>Admin</MenuItem>
                        <MenuItem value={'ReadOnly'}>Read Only</MenuItem>
                        <MenuItem value={'ReadAndWrite'}>
                            Read and Write
                        </MenuItem>
                    </Select>
                    {roleError ? (
                        <FormHelperText>{roleError}</FormHelperText>
                    ) : null}
                </FormControl>
                <Button
                    disabled={props.isWorking}
                    disableElevation
                    variant="contained"
                    color="success"
                    style={{ margin: '20px 0px 10px' }}
                    onClick={props.onSave}
                >
                    Save
                </Button>
                <Button
                    disabled={props.isWorking}
                    onClick={() => props.handleClose({}, 'none')}
                    disableElevation
                    variant="contained"
                    color="inherit"
                    style={{ margin: '20px 0 10px 10px' }}
                >
                    Cancel
                </Button>
            </Box>
        </AppModal>
    );
};

export default AdminForm;
