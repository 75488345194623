import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface AuthState {
//   accessToken?: string;
//   refreshToken?: string;
//   expiresIn?: number;
//   idToken?: string;
//   role?: string;
// }

interface AuthState {
    id?: string;
    name?: string;
    userName?: string;
    role?: string;
    avatar?: string;
}

const initialState: AuthState = {};

// const authSlice = createSlice({
//   name: 'auth',
//   initialState,
//   reducers: {
//     setAccessToken: (state, action: PayloadAction<string>) => {
//       state.accessToken = action.payload;
//     },
//     setRefreshToken: (state, action: PayloadAction<string>) => {
//       state.refreshToken = action.payload;
//     },
//     setExpiresIn: (state, action: PayloadAction<number>) => {
//       state.expiresIn = action.payload;
//     },

//     setIdToken: (state, action: PayloadAction<string>) => {
//       state.idToken = action.payload;
//     },

//     clearAccessToken: (state) => {
//       state.accessToken = undefined;
//       state.refreshToken = undefined;
//       state.expiresIn = undefined;
//       state.idToken = undefined;
//       state.role = undefined;
//     },

//     setActiveRole: (state, action: PayloadAction<string>) => {
//       state.role = action.payload;
//     }
//   },
// });

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setId: (state, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
        setUsername: (state, action: PayloadAction<string>) => {
            state.userName = action.payload;
        },
        setRole: (state, action: PayloadAction<string>) => {
            state.role = action.payload;
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setAvatar: (state, action: PayloadAction<string>) => {
            state.avatar = action.payload;
        },

        clearIdentities: (state) => {
            state.id = undefined;
            state.userName = undefined;
            state.role = undefined;
            state.name = undefined;
            state.avatar = undefined;
        },
    },
});

export const { setId, setUsername, setRole, setName, setAvatar } =
    authSlice.actions;

export default authSlice.reducer;
