import * as React from 'react';
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Session } from '@toolpad/core';
import { useSelector } from 'react-redux';
import { RootState } from '../stores';

export interface UserSession extends Session {
    id: string;
    userName: string;
    name: string;
    role: string;
    // accessToken?: string;
    // refreshToken?: string;
    // idToken?: string;
    // expiresIn?: number;
}

export interface SessionContextValue {
    session: UserSession | null | undefined;
    setSession: (session: UserSession | null | undefined) => void;
}

export const SessionContext = createContext<SessionContextValue>({
    session: null,
    setSession: () => false,
});

export function useSession() {
    return useContext(SessionContext);
}

interface SessionProviderProps {
    children: ReactNode;
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
    const [session, setSession] = useState<UserSession | null | undefined>(
        null
    );

    const authState = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        if (
            authState &&
            authState &&
            authState.id &&
            authState.userName &&
            authState.name &&
            authState.role
        ) {
            setSession({
                id: authState.id,
                userName: authState.userName,
                name: authState.name,
                role: authState.role,
            });
        }
    }, [authState]);

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            {children}
        </SessionContext.Provider>
    );
};

// export const useAuthCode = () => {
//   const { session } = useSession();
//   return session?.accessToken;
// };
