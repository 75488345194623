import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './stores';
import { StyledEngineProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { appRouter } from './router';
import { SessionProvider } from './contexts/SessionContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <StyledEngineProvider injectFirst>
        <Provider store={store}>
            <SessionProvider>
                <RouterProvider router={appRouter} />
            </SessionProvider>
        </Provider>
    </StyledEngineProvider>
);

reportWebVitals();
