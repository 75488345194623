import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { AppProvider, Navigation, Router } from '@toolpad/core/AppProvider';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import DnsIcon from '@mui/icons-material/Dns';
import { extendTheme } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores';
import { UserSession } from '../../types';
import ElmaToast from '../Shared/ElmaToast';

const appTheme = extendTheme({
    colorSchemes: { light: true, dark: true },
    colorSchemeSelector: 'class',
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});

const ADMIN_LEFT_NAVIGATION: Navigation = [
    {
        segment: 'dashboard',
        title: 'Dashboard',
        icon: <HomeIcon />,
    },
    {
        segment: 'admin',
        title: 'Admin',
        icon: <AdminPanelSettingsIcon />,
    },
    {
        segment: 'activity',
        title: 'Activity',
        icon: <ReceiptLongIcon />,
    },
    {
        segment: 'locations',
        title: 'Emergency Addresses',
        icon: <LocationCityIcon />,
    },
    {
        segment: 'trusted-ips',
        title: 'Trusted IPs',
        icon: <DnsIcon />,
    },
    {
        kind: 'divider',
    },
];

const useAppRouter = (initialPath: string): Router => {
    const navigate = useNavigate();
    const [pathname, setPathname] = useState(initialPath);

    return useMemo(() => {
        return {
            pathname,
            searchParams: new URLSearchParams(),
            navigate: (path: string | URL) => {
                setPathname(path.toString());
                navigate(path.toString());
            },
        };
    }, [navigate, pathname]);
};

const App = () => {
    const router = useAppRouter('/dashboard');
    // const navigate = useNavigate();
    const [succeeded, setSucceeded] = useState('');
    // const dispatch = useDispatch();
    // const { data } = useGetIdentityQuery();

    const user = useSelector((state: RootState) => state.auth);
    // const userRole = user?.role || null;

    const [session, setSession] = useState<UserSession | null>(null);

    useEffect(() => {
        if (user) {
            setSession({
                user: {
                    id: user.id,
                    name: user.name,
                    email: user.userName,
                    image: user.avatar,
                },
            });
        }
    }, [user]);

    const authentication = useMemo(() => {
        return {
            signIn: () => {
                setSession({
                    user: {
                        id: user.id,
                        name: user.name,
                        email: user.userName,
                        image: user.avatar,
                    },
                });
            },
            signOut: () => {
                setSession(null);
                window.sessionStorage.clear();
                setSucceeded(
                    'Successfully Logged out. Redirecting in 3 seconds....'
                );
                let timer = 2;
                const interval = setInterval(() => {
                    setSucceeded(
                        `Successfully Logged out. Redirecting in ${timer} seconds....`
                    );
                    timer--;

                    if (timer === 0) {
                        clearInterval(interval);
                        window.location.href = '/login';
                    }
                }, 1000);
            },
        };
    }, []);
    return (
        <AppProvider
            theme={appTheme}
            navigation={ADMIN_LEFT_NAVIGATION}
            router={router}
            authentication={authentication}
            session={session}
            branding={{
                logo: (
                    <img
                        src="/assets/softel-communications-logo-small.png"
                        alt="SOFTEL Communications Inc"
                    />
                ),
                title: '',
            }}
        >
            <ElmaToast succeeded={succeeded} setSucceeded={setSucceeded} />
            <Outlet />
        </AppProvider>
    );
};

export { App };
