import { get } from 'lodash';

export type OrNull<T> = T | null;

interface GetConfigInterface<T> {
    configName: string;
    defaultValue?: T;
    isRequired?: boolean;
}

interface GetConfigInterfaceWithDefault<T> extends GetConfigInterface<T> {
    defaultValue: T;
}

interface GetConfigInterfaceWithRequired<T> extends GetConfigInterface<T> {
    isRequired: true;
}

export function getConfig<T>(config: GetConfigInterfaceWithDefault<T>): T;
export function getConfig<T>(config: GetConfigInterfaceWithRequired<T>): T;
export function getConfig<T>(config: GetConfigInterface<T>): OrNull<T>;

export function getConfig<T>(config: GetConfigInterface<T>): OrNull<T> {
    const value = get(window.overrides_data, config.configName, null);
    if (!value) {
        if (config.isRequired) {
            throw new Error(`Config ${config.configName} is required`);
        }

        return config.defaultValue ?? null;
    }

    return value as unknown as OrNull<T>;
}
