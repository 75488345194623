import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { getServerEndpoint } from '../configs/app.config';

export interface CallbackParams {
    access_token: string;
    code: string;
    state: string;
    sessionState: string;
    clientInfo: string;
}

export interface AccessTokenResponse {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
}

export const loginService = createApi({
    reducerPath: 'loginService',
    baseQuery: retry(
        fetchBaseQuery({
            baseUrl: getServerEndpoint(),
            prepareHeaders: (headers, { getState }) => {
                return headers;
            },
        }),
        {
            maxRetries: 0,
        }
    ),
    endpoints: (builder) => ({
        exchangeToken: builder.mutation<AccessTokenResponse, CallbackParams>({
            query: (params: CallbackParams) => ({
                url: 'login/callback',
                method: 'POST',
                body: {
                    code: params.code,
                    state: params.state,
                    session_state: params.sessionState,
                    client_info: params.clientInfo,
                },
            }),
        }),
    }),
});

export const { useExchangeTokenMutation } = loginService;
