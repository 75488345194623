import { createBrowserRouter } from 'react-router-dom';
import { ProtectedAppLayout } from './components/Layout/ProtectedAppLayout';
import Activity from './components/Activity/Activity';
import { App } from './components/Layout/App';
import Dashboard from './components/Dashboard/Dashboard';
import { Login } from './components/Login/Login';
import { CallBack } from './components/Login/Callback';
import Admin from './components/Admin/Admin';
import Location from './components/Location/Location';
import TrustedIp from './TrustedIp/TrustedIp';

const appRouter = createBrowserRouter([
    {
        Component: App,
        children: [
            {
                path: '/',
                Component: ProtectedAppLayout,
                children: [
                    {
                        path: '/',
                        Component: Dashboard,
                    },
                    {
                        path: '/dashboard',
                        Component: Dashboard,
                    },
                    {
                        path: '/admin',
                        Component: Admin,
                    },
                    {
                        path: '/locations',
                        Component: TrustedIp,
                    },
                    {
                        path: '/trusted-ips',
                        Component: Location,
                    },
                    {
                        path: '/activity',
                        Component: Activity,
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        Component: Login,
    },
    {
        path: '/callback',
        Component: CallBack,
    },
]);

export { appRouter };
