import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { getMicrosoftGraph, getServerEndpoint } from '../configs/app.config';

interface OrganizationInterface {
    id: string;
    tenant_id: string;
    tenant_type: string;
    tenant_name: string;
    is_active: boolean;
    created_at: string;
    modified_at: string;
}

interface UserInterface {
    id: string;
    tenant_id: string;
    ms_object_id: string;
    user_name: string;
    display_name: string;
    role: string;
    created_at: string;
    modified_at: string;
}

export interface OrganizationResponse {
    organization: OrganizationInterface;
    user: UserInterface;
}

export const identityService = createApi({
    reducerPath: 'identityService',
    baseQuery: retry(
        fetchBaseQuery({
            baseUrl: getServerEndpoint(),
            prepareHeaders: (headers, { getState }) => {
                const token = window.sessionStorage.accessToken;
                if (token) {
                    headers.set('Authorization', `Bearer ${token}`);
                }

                return headers;
            },
        }),
        { maxRetries: 0 }
    ),
    endpoints: (builder) => ({
        getIdentity: builder.query<OrganizationResponse, void>({
            query: () => ({
                url: 'identities/',
            }),
        }),

        getAvatar: builder.query<any, void>({
            query: () => ({
                url: `${getMicrosoftGraph()}/v1.0/me/photos/48x48/$value`,
                responseHandler: (response: any) => response.blob(),
            }),
        }),
    }),
});

export const { useGetIdentityQuery, useGetAvatarQuery } = identityService;
