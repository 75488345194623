import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { Box, TablePagination } from '@mui/material';
import {
    ActivityInterface,
    ActivityInterfacePaginated,
    useGetActivityLogsQuery,
} from '../../services/activity.service';
import ActivityTable, { ActivityDetailBox } from './Activity-List';

const ITEMS_PER_PAGE = 5;

interface ErrorData {
    code: string;
    message: string;
}

interface QueryErrorInterface {
    data: ErrorData;
    status: number;
}

const CellLoader = (props: { totalRows: number }) => {
    return (
        <>
            {Array.from({ length: props.totalRows }).map((_, index) => (
                <TableRow key={index}>
                    <TableCell>
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                        <Skeleton animation="wave" />
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
};

const EmptyState = () => {
    return (
        <TableRow>
            <TableCell align="center" colSpan={3}>
                No logs Available
            </TableCell>
        </TableRow>
    );
};

const Activity = () => {
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(ITEMS_PER_PAGE);
    const [isPaginating, setPaginatingState] = useState(false);
    const [isWorking, setWorkingState] = useState(false);
    const { data, error, isLoading } = useGetActivityLogsQuery({
        page: page + 1,
        items_per_page: perPage,
    }) as {
        data: ActivityInterfacePaginated;
        error: QueryErrorInterface;
        isLoading: boolean;
        refetch: any;
    };
    const [lastRowCount, setLastRowCount] = useState(1);
    const [open, setOpen] = useState(false);
    const [selectedActivity, setSelectedActivity] =
        useState<ActivityInterface | null>(null);

    useEffect(() => {
        setWorkingState(isLoading);
        if (!isLoading) {
            setWorkingState(false);
            setPaginatingState(false);
            setLastRowCount(data?.items_per_page || 0);
        }
    }, [data, error, isLoading]);

    const handlePageChange = (event: unknown, newPage: number) => {
        setWorkingState(true);
        setPage(newPage);
    };

    const handlePerPageChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setWorkingState(true);
        setPaginatingState(true);
        setPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenDialog = (activity: ActivityInterface) => {
        setSelectedActivity(activity);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedActivity(null);
    };

    return (
        <Box>
            <ActivityDetailBox
                open={open}
                handleCloseDialog={handleCloseDialog}
                selectedActivity={selectedActivity}
            />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="Logs Lists">
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isWorking ? (
                            <CellLoader totalRows={lastRowCount} />
                        ) : data ? (
                            <ActivityTable
                                handleOpenDialog={handleOpenDialog}
                                data={
                                    (data?.data ??
                                        []) as unknown as ActivityInterface[]
                                }
                            />
                        ) : (
                            <EmptyState />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {(!isWorking || (isWorking && !isPaginating)) && data ? (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    component="div"
                    count={data.total_items}
                    rowsPerPage={data.items_per_page}
                    page={page || 0}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handlePerPageChange}
                />
            ) : null}
        </Box>
    );
};

export default Activity;
