import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

import styles from './Login.module.scss';
import { useSession } from '../../contexts/SessionContext';
import { Navigate } from 'react-router-dom';
import { getServerEndpoint } from '../../configs/app.config';

const Login = () => {
    const session = useSession();

    const handleSignInClick = () => {
        window.location.href = `${getServerEndpoint()}/login/`;
    };

    const loginCard = () => {
        if (session.session) {
            return <Navigate to={'/dashboard'} />;
        }

        return (
            <div className={styles.loginCard}>
                <img
                    src={'/assets/softel-communications-logo-small.png'}
                    alt={'SOFTEL Communication'}
                />
                <Typography gutterBottom variant="h6" component="div">
                    Network Automation System
                </Typography>
                <div className={styles.signInButton}>
                    <Button
                        variant={'outlined'}
                        color={'info'}
                        startIcon={<MicrosoftIcon />}
                        fullWidth={true}
                        onClick={() => handleSignInClick()}
                    >
                        Sign in With Microsoft
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.loginContainer}>
            <Box
                sx={{
                    width: 320,
                    height: 320,
                    borderRadius: 1,
                }}
            >
                {loginCard()}
            </Box>
        </div>
    );
};

export { Login };
