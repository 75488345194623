import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { getServerEndpoint } from '../configs/app.config';

export interface ActivityInterface {
    id: string;
    tenant_id: string;
    title: string;
    user_name: string;
    description: string;
    status: string;
    event_source: string;
    created_at: string;
    event_name: string;
}

export interface ActivityInterfacePaginated {
    data: Array<ActivityInterface>;
    items_per_page: number;
    page: number;
    total_items: number;
}

export const activityService = createApi({
    reducerPath: 'activityService',
    baseQuery: retry(
        fetchBaseQuery({
            baseUrl: getServerEndpoint(),
            prepareHeaders: (headers, { getState }) => {
                const token = window.sessionStorage.accessToken;
                if (token) {
                    headers.set('Authorization', `Bearer ${token}`);
                }
                return headers;
            },
        }),
        {
            maxRetries: 0,
        }
    ),
    endpoints: (builder) => ({
        getActivityLogs: builder.query<
            ActivityInterfacePaginated,
            { page: number; items_per_page: number }
        >({
            query: ({ page, items_per_page }) => ({
                url: 'activities/',
                params: { page, items_per_page },
            }),
        }),
    }),
});

export const { useGetActivityLogsQuery } = activityService;
