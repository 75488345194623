import * as React from 'react';
import { DashboardLayout, PageContainer } from '@toolpad/core';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PageLoader from '../Shared/PageLoader';
import { SidebarFooterAccount } from './SidebarAccountPreview';
import {
    useGetAvatarQuery,
    useGetIdentityQuery,
} from '../../services/identity.service';
import {
    setAvatar,
    setId,
    setName,
    setRole,
    setUsername,
} from '../../stores/authSlice';
import { useDispatch } from 'react-redux';

const ProtectedAppLayout = () => {
    const navigate = useNavigate();
    const { data, isLoading } = useGetIdentityQuery();
    const avatarQuery = useGetAvatarQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!window.sessionStorage.accessToken || (!isLoading && !data)) {
            navigate('/login');
        }

        if (data?.user) {
            dispatch(setId(data.user.id));
            dispatch(setUsername(data.user.user_name));
            dispatch(setName(data.user.display_name));
            dispatch(setRole(data.user.role));
        }

        if (avatarQuery.isSuccess && avatarQuery.data) {
            dispatch(setAvatar(URL.createObjectURL(avatarQuery.data)));
        }
    }, [data, avatarQuery]);

    return isLoading ? (
        <PageLoader text="We are fetching your data. Please wait...." />
    ) : (
        <DashboardLayout
            slots={{
                toolbarAccount: () => null,
                sidebarFooter: SidebarFooterAccount,
            }}
        >
            <PageContainer>
                <Outlet />
            </PageContainer>
        </DashboardLayout>
    );
};

export { ProtectedAppLayout };
