import * as React from 'react';
import styles from './Login.module.scss';
import {
    CallbackParams,
    useExchangeTokenMutation,
} from '../../services/login.service';
import { useEffect } from 'react';
import { Box, Card, CardContent, Link, Typography } from '@mui/material';
import { Login } from './Login';
import { useNavigate } from 'react-router-dom';
import { useSessionStorageState } from '@toolpad/core';
import PageLoader from '../Shared/PageLoader';

const CallBack = () => {
    const [exchangeToken, { isLoading, error }] = useExchangeTokenMutation();
    const [, setAccessToken] = useSessionStorageState('accessToken', null);
    const [, setExpiresIn] = useSessionStorageState('expiresIn', 0);
    const [, setIdToken] = useSessionStorageState('idToken', null);
    const [, setRefreshToken] = useSessionStorageState('refreshToken', null);

    const navigate = useNavigate();

    useEffect(() => {
        const handleCallback = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const state = urlParams.get('state');
            const sessionState = urlParams.get('session_state');
            const clientInfo = urlParams.get('client_info');

            if (code && state && sessionState && clientInfo) {
                const response = await exchangeToken({
                    code,
                    state,
                    sessionState,
                    clientInfo,
                } as CallbackParams);

                if (response.data) {
                    setAccessToken(response.data.access_token);
                    setRefreshToken(response.data.refresh_token);
                    setExpiresIn(response.data.expires_in);
                    setIdToken(response.data.id_token);
                    navigate('/dashboard');
                }
            }
        };

        handleCallback(); /* .then(() => {}) */
    }, [
        navigate,
        exchangeToken,
        setAccessToken,
        setRefreshToken,
        setExpiresIn,
        setIdToken,
    ]);

    return (
        <div className={styles.loginContainer}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                {isLoading ? (
                    <PageLoader text="Logging you in. Please wait...." />
                ) : error ? (
                    <Login />
                ) : (
                    <Card sx={{ minWidth: 300, textAlign: 'center' }}>
                        <CardContent>
                            <Typography variant="h6" color="green">
                                Access token generated successfully. If not
                                redirected,{' '}
                                <Link href="/dashboard">Click Here</Link>
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </div>
    );
};

export { CallBack };
