import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const PageLoader = (props: { text: string }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                bgcolor: '#fff',
            }}
        >
            <CircularProgress size={60} />
            <Typography sx={{ mt: 2, fontSize: '18px', fontWeight: '500' }}>
                {props.text
                    ? props.text
                    : 'We are fetching your data. Please wait....'}
            </Typography>
        </Box>
    );
};

export default PageLoader;
