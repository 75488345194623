import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { animated, useSpring } from '@react-spring/web';

interface FadeProps {
    children: React.ReactElement<any>;
    in?: boolean;
    onClick?: any;
    onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
    onExited?: (node: HTMLElement, isAppearing: boolean) => void;
    // ownerState?: any;
}

interface ModalProps {
    open: boolean;
    handleClose:
        | ((event: object, reason: 'backdropClick' | 'escapeKeyDown') => void)
        | undefined;
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(
    function Fade(props, ref) {
        const {
            children,
            in: open,
            onClick,
            onEnter,
            onExited,
            // ownerState,
            ...other
        } = props;
        const style = useSpring({
            from: { opacity: 0 },
            to: { opacity: open ? 1 : 0 },
            onStart: () => {
                if (open && onEnter) {
                    onEnter(null as any, true);
                }
            },
            onRest: () => {
                if (!open && onExited) {
                    onExited(null as any, true);
                }
            },
        });

        return (
            <animated.div ref={ref} style={style} {...other}>
                {React.cloneElement(children, { onClick })}
            </animated.div>
        );
    }
);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function AppModal(props: ModalProps) {
    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={props.open}
                onClose={() => false}
                disableEscapeKeyDown={true}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={props.open}>
                    <Box sx={style}>
                        {typeof props.children === 'function'
                            ? props.children()
                            : props.children}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
