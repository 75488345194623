import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ActivityInterface } from '../../services/activity.service';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';

interface LogTableProps {
    data: ActivityInterface[];
    handleOpenDialog: (arg0: ActivityInterface) => void;
}

interface LogDetailProps {
    open: boolean;
    handleCloseDialog: (event: React.MouseEvent<HTMLButtonElement>) => void;
    selectedActivity: ActivityInterface | null;
}

export const ActivityDetailBox = (props: LogDetailProps) => {
    return (
        <Dialog open={props.open} onClose={props.handleCloseDialog}>
            <DialogTitle>Activity Details</DialogTitle>
            {props.selectedActivity && (
                <DialogContent>
                    <Typography variant="subtitle1">
                        <strong>Title:</strong> {props.selectedActivity.title}
                    </Typography>
                    <Typography variant="subtitle1">
                        <strong>Event Source:</strong>{' '}
                        {props.selectedActivity.event_source}
                    </Typography>
                    <Typography variant="subtitle1">
                        <strong>Event Name:</strong>{' '}
                        {props.selectedActivity.event_name}
                    </Typography>
                    <Typography variant="subtitle1">
                        <strong>User:</strong>{' '}
                        {props.selectedActivity.user_name}
                    </Typography>
                    <Typography variant="subtitle1">
                        <strong>Time:</strong>{' '}
                        {props.selectedActivity.created_at}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Details:</strong>{' '}
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {props.selectedActivity.description}
                        </pre>
                    </Typography>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={props.handleCloseDialog} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ActivityTable = (props: LogTableProps) => {
    return (
        <>
            {props.data.map((log: any) => (
                <TableRow
                    style={{ cursor: 'pointer' }}
                    key={`log-${log.id}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => props.handleOpenDialog(log)}
                >
                    <TableCell>{log.title}</TableCell>
                    <TableCell>{log.user_name}</TableCell>
                    <TableCell>{log.created_at}</TableCell>
                </TableRow>
            ))}
        </>
    );
};

export default ActivityTable;
