import * as React from 'react';
import { Snackbar } from '@mui/material';

interface ElmaPropsType {
    succeeded: string;
    setSucceeded: (arg0: string) => void;
}

export default function ElmaToast(props: ElmaPropsType) {
    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={props.succeeded ? true : false}
                onClose={() => props.setSucceeded('')}
                message={props.succeeded}
                autoHideDuration={5000}
                color="success"
            />
        </React.Fragment>
    );
}
