import { getConfig } from '../utils/config';

export const getServerEndpoint = (): string =>
    getConfig({ configName: 'api.SERVER_ENDPOINT', isRequired: true });
export const getMicrosoftGraph = (): string =>
    getConfig({
        configName: 'api.MICROSOFT_GRAPH',
        isRequired: false,
        defaultValue: 'https://graph.microsoft.com',
    });
