import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { UserInterface } from '../../services/user.service';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

interface UserTableProps {
    data: UserInterface[];
    onDelete: (arg: any) => void;
    onEdit: (payload: UserInterface) => void;
    canDelete: boolean;
    canEdit: boolean;
    viewerId?: string;
}

const AdminTable = (props: UserTableProps) => {
    return (
        <>
            {props.data.map((user: any) => (
                <TableRow
                    key={`user-${user.id}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell>{user.user_name}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell align="right">
                        {props.canDelete ? (
                            <IconButton
                                aria-label="Delete data"
                                color="error"
                                onClick={() => props.onDelete(user.id)}
                                disabled={
                                    !(
                                        props.viewerId &&
                                        props.viewerId != user.id
                                    )
                                }
                            >
                                <DeleteIcon />
                            </IconButton>
                        ) : null}
                        {props.canEdit ? (
                            <IconButton
                                aria-label="Edit data"
                                color="info"
                                onClick={() => props.onEdit(user)}
                                disabled={
                                    props.viewerId && props.viewerId != user.id
                                        ? false
                                        : true
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        ) : null}
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
};

export default AdminTable;
