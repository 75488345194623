const VerbatimCodes = {
    'ELMA-0001': 'Page not found.',
    'ELMA-0010': 'Resource already exists.',
    'ELMA-0020': 'Request precondition failed.',
    'ELMA-0021': 'Request precondition failed: Admin access required.',
    'ELMA-0022': 'Request precondition failed: Different user required.',
    'ELMA-0030': 'Request has no header token.',
    'ELMA-0031': 'Request has an invalid header token type.',
    'ELMA-0050': 'Invalid Microsoft Graph response for tenant.',
    'ELMA-0051': 'Invalid Microsoft Graph response for user.',
    'ELMA-0060': 'Error creating default tenant.',
    'ELMA-0061': 'Error: Inactive tenant.',
    'ELMA-0070': 'Server error.',
    'ELMA-0071': 'Server error: Invalid middleware.',
};

export type VerbatimCodeTypes = keyof typeof VerbatimCodes;

export const getError = (key: VerbatimCodeTypes): string => {
    return VerbatimCodes[key] || 'Something went wrong';
};
