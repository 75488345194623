import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { getServerEndpoint } from '../configs/app.config';

export interface UserInterface {
    id: string;
    tenant_id: string;
    ms_object_id: string;
    user_name: string;
    role: string;
    created_at: string;
    modified_at: string;
}

export interface UserInterfacePaginated {
    data: Array<UserInterface>;
    items_per_page: number;
    page: number;
    total_items: number;
}

export interface CreateUserParams {
    user_name: string;
    role: string;
}

export interface UpdateUserParams {
    user_id: string;
    role: string;
}

export interface DeleteUserParams {
    user_id: string;
}

export const userService = createApi({
    reducerPath: 'userService',
    baseQuery: retry(
        fetchBaseQuery({
            baseUrl: getServerEndpoint(),
            prepareHeaders: (headers, { getState }) => {
                const token = window.sessionStorage.accessToken;
                if (token) {
                    headers.set('Authorization', `Bearer ${token}`);
                }
                return headers;
            },
        }),
        {
            maxRetries: 0,
        }
    ),
    endpoints: (builder) => ({
        getUserCollection: builder.query<
            UserInterfacePaginated,
            { page: number; items_per_page: number }
        >({
            query: ({ page, items_per_page }) => ({
                url: 'users/',
                params: { page, items_per_page },
            }),
        }),

        getUser: builder.query<UserInterface, string>({
            query: (user_id: string) => ({
                url: `users/view/${user_id}/`,
            }),
        }),

        createUser: builder.mutation<UserInterface, CreateUserParams>({
            query: (params: CreateUserParams) => ({
                url: 'users/create',
                method: 'POST',
                body: {
                    user_name: params.user_name,
                    role: params.role,
                },
            }),
        }),

        updateUser: builder.mutation<UserInterface, UpdateUserParams>({
            query: (params: UpdateUserParams) => ({
                url: `users/update/${params.user_id}`,
                method: 'PUT',
                body: {
                    role: params.role,
                },
            }),
        }),

        deleteUser: builder.mutation<UserInterface, DeleteUserParams>({
            query: (params: DeleteUserParams) => ({
                url: `users/delete/${params.user_id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetUserCollectionQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = userService;
