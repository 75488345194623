import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { loginService } from '../services/login.service';
import { identityService } from '../services/identity.service';
import { userService } from '../services/user.service';
import authSlice from './authSlice';
import { activityService } from '../services/activity.service';

const rootReducer = combineReducers({
    [loginService.reducerPath]: loginService.reducer,
    [identityService.reducerPath]: identityService.reducer,
    [userService.reducerPath]: userService.reducer,
    [activityService.reducerPath]: activityService.reducer,
    auth: authSlice,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            loginService.middleware,
            identityService.middleware,
            userService.middleware,
            activityService.middleware
        ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
