import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import {
    useCreateUserMutation,
    useDeleteUserMutation,
    useGetUserCollectionQuery,
    UserInterface,
    UserInterfacePaginated,
    useUpdateUserMutation,
} from '../../services/user.service';
import { Alert, Box, Button, TablePagination } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ConfirmBox from '../Shared/ConfirmBox';
import { RootState } from '../../stores';
import { useSelector } from 'react-redux';
import AdminForm from './Admin-Form';
import AdminTable from './Admin-List';
import {
    ErroredResponse,
    AdminFieldErrorKeys,
    QueryErrorReturnType,
} from '../../types';
import { getError, VerbatimCodeTypes } from '../../utils';
import ElmaToast from '../Shared/ElmaToast';

const ITEMS_PER_PAGE = 10;

interface ErrorData {
    code: string;
    message: string;
}

interface QueryErrorInterface {
    data: ErrorData;
    status: number;
}

const CellLoader = (props: { totalRows: number }) => {
    return (
        <>
            {Array.from({ length: props.totalRows }).map((_, index) => (
                <TableRow key={index}>
                    <TableCell>
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                        <Skeleton animation="wave" />
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
};

const EmptyState = () => {
    return (
        <TableRow>
            <TableCell align="center" colSpan={3}>
                No User Available
            </TableCell>
        </TableRow>
    );
};

const Admin = () => {
    const user = useSelector((state: RootState) => state.auth);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(ITEMS_PER_PAGE);
    const [isConfirmOpen, setConfirmOpen] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [isFormModalOpen, setFormModalOpen] = useState(false);
    const [isPaginating, setPaginatingState] = useState(false);
    const [isWorking, setWorkingState] = useState(false);
    const { data, error, isLoading, refetch } = useGetUserCollectionQuery({
        page: page + 1,
        items_per_page: perPage,
    }) as {
        data: UserInterfacePaginated;
        error: QueryErrorInterface;
        isLoading: boolean;
        refetch: any;
    };
    const [lastRowCount, setLastRowCount] = useState(1);
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [deleteUser] = useDeleteUserMutation();
    const [email, setEmail] = useState<string>('');
    const [role, setRole] = useState<string>('ReadOnly');
    const [userId, setUserId] = useState<string>('');
    const [formError, setFormError] = useState<string>('');
    const [fieldErrors, setFieldErrors] = useState<
        Record<AdminFieldErrorKeys, string>
    >({
        user_name: '',
        role: '',
    });
    const [tableError, setTableError] = useState<string>('');
    const [succeeded, setSucceeded] = useState<string>('');

    const can = (action: string) => {
        if (user.role === 'Admin') {
            return ['CREATE', 'UPDATE', 'DELETE', 'READ'].includes(action);
        } else if (user.role === 'ReadAndWrite') {
            return false;
        } else if (user.role === 'ReadOnly') {
            return false;
        }

        return false;
    };

    useEffect(() => {
        setWorkingState(isLoading);
        if (!isLoading) {
            setWorkingState(false);
            setPaginatingState(false);
            setLastRowCount(data?.items_per_page || 0);

            if (error && error.data.message) {
                setTableError(error.data.message);
            }
        }
    }, [data, error, isLoading]);

    const handlePageChange = (event: unknown, newPage: number) => {
        setWorkingState(true);
        setPage(newPage);
    };

    const handlePerPageChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setWorkingState(true);
        setPaginatingState(true);
        setPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDeletion = (id: string) => {
        setPaginatingState(true);
        setUserId(id);
        setConfirmOpen(true);
    };

    const handleConfirmBoxDeniedRequest = () => {
        setConfirmOpen(false);
        setUserId('');
    };

    const handleConfirmBoxAcceptRequest = async () => {
        setWorkingState(true);
        const response = (await deleteUser({ user_id: userId })) as
            | UserInterface
            | ErroredResponse;
        setConfirmOpen(false);
        if (response && 'error' in response) {
            setTableError(
                getError(
                    response?.error?.data?.verbatim_code as VerbatimCodeTypes
                )
            );
            setWorkingState(false);
        } else {
            setPage(0);
            setPerPage(ITEMS_PER_PAGE);
            setConfirmOpen(false);
            setSucceeded('Admin deleted successfully');
            refetch();
        }
    };

    const handleCreateClick = () => {
        setFormError('');
        setSucceeded('');
        setFieldErrors({ user_name: '', role: '' });
        setUserId('');
        setEmail('');
        setRole('ReadOnly');
        setEdit(false);
        setFormModalOpen(true);
    };

    const handleEditClick = (user: UserInterface) => {
        setFormError('');
        setSucceeded('');
        setFieldErrors({ user_name: '', role: '' });
        setEdit(true);
        setUserId(user.id);
        setEmail(user.user_name);
        setRole(user.role);
        setFormModalOpen(true);
    };

    const handleSaveAction = async () => {
        setWorkingState(true);
        let response: UserInterface | ErroredResponse | null = null;
        const isValid = validateForm(isEdit);

        if (isEdit && isValid) {
            response = (await updateUser({ user_id: userId, role: role })) as
                | UserInterface
                | ErroredResponse;
        } else if (isValid) {
            response = (await createUser({
                user_name: email,
                role: role,
            })) as UserInterface | ErroredResponse;
        }

        if (!isValid) {
            setWorkingState(false);
            return;
        }

        if (response && 'error' in response) {
            setFormError(
                getError(
                    response?.error?.data?.verbatim_code as VerbatimCodeTypes
                )
            );
            maybeSetFormFieldError(response?.error?.data);
        } else {
            setSucceeded(
                isEdit
                    ? 'Admin updated successfully'
                    : 'Admin created successfully'
            );
            setFormModalOpen(false);
            setPage(0);
            refetch();
        }
        setWorkingState(false);
    };

    const closeModal = () => {
        setWorkingState(false);
        setFormModalOpen(false);
    };

    const maybeSetFormFieldError = (response: QueryErrorReturnType) => {
        const errors = { user_name: '', role: '' };
        if (response.verbatim_code !== 'ELMA-0020') {
            return false;
        }

        if (!response.error.error_messages) {
            return false;
        }

        for (const individualError of response.error.error_messages) {
            const field = individualError.field as AdminFieldErrorKeys;
            errors[field] = individualError.message;
        }

        setFieldErrors(errors);
    };

    const isEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return false;
        }

        const [localPart, domain] = email.split('@');
        if (email.length > 254 || localPart.length > 64) {
            return false;
        }

        const domainParts = domain.split('.');
        return !(
            domainParts.length < 2 ||
            domainParts[domainParts.length - 1].length < 2
        );
    };

    const validateForm = (isEdit = false) => {
        const errors = { ...fieldErrors };
        let hasError = false;
        if (!isEdit) {
            if (!email) {
                errors['user_name'] = 'Email is required';
                hasError = true;
            } else if (!isEmail(email)) {
                errors['user_name'] = 'Invalid Email';
                hasError = true;
            }
        }

        if (!role) {
            hasError = true;
            errors['role'] = 'Role is Required';
        }

        if (hasError) {
            setFieldErrors(errors);
            return false;
        }

        return true;
    };

    return (
        <Box>
            <ElmaToast succeeded={succeeded} setSucceeded={setSucceeded} />
            <ConfirmBox
                isBtnDisabled={isWorking}
                isOpen={isConfirmOpen}
                onCloseRequested={handleConfirmBoxDeniedRequest}
                onConfirmRequested={handleConfirmBoxAcceptRequest}
            />
            <AdminForm
                open={isFormModalOpen}
                isWorking={isWorking}
                handleClose={closeModal}
                onRoleChange={(event) => setRole(event.target.value)}
                onEmailChange={(event) => setEmail(event.target.value)}
                email={email}
                role={role}
                isEdit={isEdit}
                onSave={handleSaveAction}
                error={formError}
                fieldErrors={fieldErrors}
            />
            {tableError ? (
                <Alert
                    variant="outlined"
                    severity="error"
                    style={{ margin: '10px 0' }}
                    onClose={() => setTableError('')}
                >
                    {tableError}
                </Alert>
            ) : null}
            {can('CREATE') ? (
                <Box style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        disabled={isWorking}
                        variant="outlined"
                        startIcon={<AddIcon />}
                        style={{ marginBottom: 20 }}
                        onClick={handleCreateClick}
                    >
                        {' '}
                        Add New Admin
                    </Button>
                </Box>
            ) : null}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="User Lists">
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isWorking ? (
                            <CellLoader totalRows={lastRowCount} />
                        ) : data ? (
                            <AdminTable
                                data={
                                    (data?.data ??
                                        []) as unknown as UserInterface[]
                                }
                                onDelete={handleDeletion}
                                onEdit={handleEditClick}
                                canDelete={can('DELETE')}
                                canEdit={can('UPDATE')}
                                viewerId={user.id}
                            />
                        ) : (
                            <EmptyState />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {(!isWorking || (isWorking && !isPaginating)) && data ? (
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={data.total_items}
                    rowsPerPage={data.items_per_page}
                    page={page || 0}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handlePerPageChange}
                />
            ) : null}
        </Box>
    );
};

export default Admin;
