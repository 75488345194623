import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface ConfirmBoxPropsTypes {
    isOpen: boolean;
    onCloseRequested: React.MouseEventHandler<HTMLButtonElement> | undefined;
    onConfirmRequested: React.MouseEventHandler<HTMLButtonElement> | undefined;
    isBtnDisabled?: boolean;
}

export default function ConfirmBox(props: ConfirmBoxPropsTypes) {
    return (
        <React.Fragment>
            <Dialog
                open={props.isOpen}
                onClose={props.onCloseRequested}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'You sure you want to proceed?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are You Sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={
                            props.isBtnDisabled ? props.isBtnDisabled : false
                        }
                        onClick={props.onCloseRequested}
                    >
                        No
                    </Button>
                    <Button
                        disabled={
                            props.isBtnDisabled ? props.isBtnDisabled : false
                        }
                        onClick={props.onConfirmRequested}
                        autoFocus
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
